<template>
  <main class="main">
    <section class="left">
      <div class="login_outer flex flex-col">
        <div>
          <h1 class="text-xl font-bold">{{ msg }}</h1>
        </div>
        <div class="pt-10">
          <router-link :to="{ name: 'login' }">
            <vs-button> 回商家後台 </vs-button>
          </router-link>
        </div>
      </div>
    </section>

    <section class="right"></section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      msg: '',
    }
  },
  created() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      this.$axios({
        url: 'front/set/person/uAccountEnable.php',
        headers: {},
        kcval: true,
        data: {
          lang: 0,
          kdata: this.$route.query.kdata,
        },
      }).then((res) => {
        if (res.data.Success) {
          // console.log(res.data.Data)
          // this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          // this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        this.msg = res.data.Message
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  @apply flex flex-col lg:flex-row h-screen text-gray-text_dark;
}

.left {
  @apply h-full relative flex-shrink-0 flex-grow
  flex items-center justify-center;

  background: url('../assets/images/login/bg1.jpg') center/cover;

  @screen lg {
    min-width: 500px;
    background: white;
  }
}

.login_outer {
  @apply bg-white
    rounded-2xl lg:absolute 
    p-6 shadow-2xl
    flex items-center justify-center
    lg:top-1/2 lg:-right-3/4 lg:transform lg:-translate-y-1/2
    w-full h-auto m-3 sm:m-0;

  background: rgba(255, 255, 255, 1);

  @screen sm {
    width: 600px;
    height: 300px;
  }
}

.right {
  @apply h-full w-full lg:w-16/20 hidden lg:block;
  background: url('../assets/images/login/bg1.jpg') center/cover;
}
</style>
